import React, { Fragment } from "react";

import { Helmet } from "react-helmet";
import { Link } from "gatsby";

import { withStyles } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import Grid from "@material-ui/core/Grid";
import Paper from "@material-ui/core/Paper";
import Card from "@material-ui/core/Card";
import CardActions from "@material-ui/core/CardActions";
import CardContent from "@material-ui/core/CardContent";
import Button from "@material-ui/core/Button";
import Chip from "@material-ui/core/Chip";

import withRoot from "../withRoot";
import Header from "../component/header";

const WeeklyTechRecap = ({ pageContext, classes }) => {
  const pages = pageContext.weeklyPages;
  const totalTags = pageContext.totalTags;

  let totalArticles = 0;

  pages.forEach((page) => {
    totalArticles += page.childrenWallabagArticles.length;
  });

  return (
    <Fragment>
      <Helmet>
        <meta charSet="utf-8" />
        <title>
          Weekly tech read - Sébastien Viande - Developper FullStack
        </title>
        <meta name="description" content="Personal website, portfolio, blog." />
        <link
          rel="apple-touch-icon"
          sizes="180x180"
          href="/apple-touch-icon.png"
        />
        <link
          rel="icon"
          type="image/png"
          sizes="32x32"
          href="/favicon-32x32.png"
        />
        <link
          rel="icon"
          type="image/png"
          sizes="16x16"
          href="/favicon-16x16.png"
        />
      </Helmet>
      <Header />
      <Paper id="main" className={classes.main}>
        <Typography color="primary" align="center" variant="h1" gutterBottom>
          {"Weekly tech read"}
        </Typography>
        <Typography align="center">
          {totalArticles} articles read since 2017 classified in{" "}
          <Link to={`/weekly-tech-read/tags`}>{totalTags} tags</Link>
        </Typography>
        <Grid container spacing={2} direction="row">
          {pages.map((page) => {
            const tags = {};
            page.childrenWallabagArticles.forEach((article) => {
              article.tags.forEach((tag) => {
                if (!tags[tag.slug]) {
                  tags[tag.slug] = {
                    ...tag,
                    count: 1,
                  };
                }
                tags[tag.slug].count += 1;
              });
            });

            const viewableTags = Object.values(tags)
              .sort((a, b) => a - b)
              .slice(0, 5);

            return (
              <Grid key={page.name} item xs={6} md={3} lg={2}>
                <Card className={classes.card}>
                  <CardContent>
                    <Typography color="textSecondary" gutterBottom>
                      Year: {page.year}
                    </Typography>
                    <Typography variant="h5" component="h2">
                      Week {page.week}
                    </Typography>
                    <Typography color="textSecondary">
                      {page.childrenWallabagArticles.length} articles
                      <Grid container alignItems="center" spacing={1}>
                        {viewableTags.map((tag) => {
                          return (
                            <Grid item key={tag.slug}>
                              <Chip
                                component={Link}
                                to={`/weekly-tech-read/tags/${tag.label}`}
                                size="small"
                                label={`${tag.label}`}
                                color="primary"
                                className={classes.link}
                              />
                            </Grid>
                          );
                        })}
                      </Grid>
                    </Typography>
                  </CardContent>
                  <CardActions>
                    <Button
                      component={Link}
                      to={`/weekly-tech-read/${page.name}/`}
                      size="small"
                    >
                      Learn More
                    </Button>
                  </CardActions>
                </Card>
              </Grid>
            );
          })}
        </Grid>
      </Paper>
    </Fragment>
  );
};

const styles = ({ spacing, breakpoints }) => ({
  main: {
    position: "relative",
    marginTop: "-5rem",
    marginBottom: "5rem",
    marginLeft: "1rem",
    marginRight: "1rem",
    [breakpoints.up("md")]: {
      marginLeft: "5rem",
      marginRight: "5rem",
    },
    padding: spacing(2),
  },
  card: {
    height: "100%",
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",
  },
  link: {
    cursor: "pointer",
  },
});

export default withRoot(withStyles(styles)(WeeklyTechRecap));
